.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 90.5%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        background: rgba(0,0,0, 0.7);
        padding: 25px;

        &--title {
            width: 100%;
            text-align: center;
            font-size: $font-size-50;
            font-weight: normal;
            color: #e20613;
            //text-shadow: 0 0 10px $color-black;
        }
        &--subtitle {
            width: 100%;
            text-align: center;
            font-size: 3vw;
            font-weight: 300;
            color: $color-1;
            text-shadow: 0 0 10px $color-black;
        }
    }
    @media screen and (max-width: 1870px) {
        .module-header {
            &--caption {
                top: 90%;
            }
        }
        @media screen and (max-width: 1740px) {
            .module-header {
                &--caption {
                    top: 89.5%;
                }
            }
            @media screen and (max-width: 1660px) {
                .module-header {
                    &--caption {
                        top: 89%;
                    }
                }
                @media screen and (max-width: 1550px) {
                    .module-header {
                        &--caption {
                            top: 86%;
                        }
                    }
                    @media screen and (max-width: 1024px) {
                        .module-header {
                            &--caption {
                                top: 83%;
                                padding: 15px;
                                &--title {
                                    font-size: 2rem;
                                }
                            }
                        }
                        @media screen and (max-width: $size-xs-max) {
                            .module-header {
                                &--caption {
                                    top: 90%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
