.btn {
    background-color: #e20613;
    color: $color-white;
    border: none;
    border-radius: 0;
    padding: 5px;
    font-weight: 500;
    font-size: $font-size-22;
    width: 100%;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        color: $color-black;
        
    }
}
