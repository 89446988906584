// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

p {
    font-weight: normal;
    font-size: $font-size-24;
    color: #232323;
    line-height: 1.6;
    margin: 0;
    @media screen and (max-width: 1550px) {
        font-size: 1rem;
    }
}

.border {
    width: 100%;
    background: #e20613;
    height: 7px;
    border-top: 2px solid #fff;
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-image-text {
    background: #dbdbdb;
    padding: 100px 8% 75px 8%;
    h3 {
        font-weight: normal;
        font-size: $font-size-38;
        color: #232323;
        text-transform: initial;
        span {
            font-weight: bold;
        }
    }
    .image-text {
        .image-side {
            width: 50%;
        }
        a.link.image-side {
            .image {
                position: relative;
                img {
                    &:nth-child(1) {
                        opacity: 1;
                        transition: 0.3s;
                    }
                    &:nth-child(2) {
                        position: absolute;
                        opacity: 0;
                        top: 0;
                        transition: 0.3s;
                    }
                }
            }
            &:hover {
                img {
                    &:nth-child(1) {
                        opacity: 0;
                        transition: 0.3s;
                    }
                    &:nth-child(2) {
                        opacity: 1;
                        transition: 0.3s;
                    }
                }
            }
        }
        .text-box {
            width: 50%;
            h4 {
                font-weight: bold;
                font-size: $font-size-24;
                color: #232323;
                padding-bottom: 10px;
            }
            a.btn-link {
                background: #000;
                display: block;
                width: 250px;
                text-align: center;
                padding: 10px;
                margin: 35px auto 0 auto;
                transition: 0.3s;
                h4 {
                    font-weight: normal;
                    font-size: $font-size-24;
                    color: $color-white;
                    padding-bottom: 0;
                }
                &:hover {
                    background: #e20613;
                    transition: 0.3s;
                }
            }
        }
    }
    @media screen and (max-width: 1400px) {
        padding: 100px 5% 75px 5%;
        @media screen and (max-width: 1150px) {
            .image-text {
                flex-direction: column;
                align-items: initial;
                .image-side {
                    width: 80%;
                    margin: 0 auto;
                }
                .text-box {
                    width: 100%;
                    margin-top: 35px;
                }
            }
            .image-text.flex.pt10 {
                padding-top: 0;
                .text-box.mr8 {
                    margin-bottom: 35px;
                }
            }
            @media screen and (max-width: 1024px) {
                padding: 75px 5%;
                @media screen and (max-width: $size-sm-max) {
                    h3 br {
                        display: none;
                    }
                    .image-text {
                        .image-side {
                            width: 100%;
                            
                        }
                    }
                }
            }
        }
    }
}

#section-background {
    padding: 25px 2%;
    border-top: 7px solid #e20613;
    border-bottom: 7px solid #e20613;
    text-align: center;
    h3 {
        font-weight: normal;
        font-size: $font-size-38;
        color: #232323;
        text-transform: initial;
        span {
            font-weight: bold;
        }
    }
    @media screen and (max-width: $size-sm-max) {
        h3 {
            font-size: 1.7rem;
            br {
                display: none;
            }
        }
    }
}

#section-burst {
    background: #000;
    padding: 50px 2%;
    a.burst {
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon {
            padding-bottom: 15px;
            position: relative;
            img {
                &:nth-child(1) {
                    opacity: 1;
                    transition: 0.3s;
                }
                &:nth-child(2) {
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    transition: 0.3s;
                }
            }
        }
        .text {
            h4, h3 {
                text-align: center;
                font-size: $font-size-30;
                color: #e20613;
                transition: 0.3s;
            }
            h4 {    
                font-weight: normal;
            }
            h3 {
                font-weight: bold;
            }
        }
        &:hover {
            h4, h3 {
                color: $color-white;
                transition: 0.3s;
            }
            img {
                &:nth-child(1) {
                    opacity: 0;
                    transition: 0.3s;
                }
                &:nth-child(2) {
                    opacity: 1;
                    transition: 0.3s;
                }
            }
        }
    }
    @media screen and (max-width: $size-sm-max) {
        a.burst .text h4, a.burst .text h3 {
            font-size: 1.3rem;
        }
        @media screen and (max-width: $size-xs-max) {
            flex-direction: column;
            a.burst.middle {
                margin: 35px 0;
            }
        }
    }
}

/*******************************************************************************
* PRODUITS
*******************************************************************************/

#section-image-text.produits {
    padding: 75px 8%;
    .image-text {
        .text-box {
            h4 {
                font-size: $font-size-28;
                padding-bottom: 35px;
                span {
                    font-weight: 500;
                }
            }
            ul {
                padding-left: 60px;
                li {
                    font-weight: normal;
                    font-size: $font-size-28;
                    color: #e20613;
                    line-height: 1.5;
                    padding-bottom: 5px;
                    text-transform: initial;
                }
            }
        }
    }
    @media screen and (max-width: 1400px) {
        padding: 75px 5%;
        @media screen and (max-width: $size-xs-max) {
            .image-text {
                .text-box {
                    ul {
                        padding-left: 25px;
                        li {
                            font-size: 1.3rem;
                        }
                    }
                }
            }            
        }
    }
}

#section-list {
    padding: 75px 8%;
    background: #000;
    border-bottom: 5px solid #e20613;
    h4 {
        font-weight: bold;
        font-size: $font-size-28;
        color: $color-white;
        padding-bottom: 35px;
    }
    ul {
        padding-left: 60px;
        li {
            font-weight: normal;
            font-size: $font-size-28;
            color: #e20613;
            line-height: 1.5;
            padding-bottom: 5px;
            text-transform: initial;
        }
    }
    .left-side {
        width: 50%;
        margin-right: 2%;
    }
    @media screen and (max-width: 1450px) {
        h4 {
            font-size: 1.25rem;
        }
        ul {
            li {
                font-size: 1.2rem;
            }
        }
        @media screen and (max-width: 1400px) {
            padding: 75px 5%;
            @media screen and (max-width: 1024px) {
                flex-direction: column;
                .left-side {
                    width: 100%;
                    margin: 0 0 25px 0;
                }
                @media screen and (max-width: $size-xs-max) {
                    ul {
                        padding-left: 25px;
                        li {
                            font-size: 1.3rem;
                        }
                    }   
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-image-text.services {
    padding: 75px 8%;
    .image-text {
        .text-box {
            h4 {
                font-size: $font-size-28;
                padding-bottom: 35px;
                span {
                    font-weight: 500;
                }
            }
        }
        .section-list {
            h4 {
                font-weight: bold;
                font-size: $font-size-28;
                color: #232323;
                padding-bottom: 35px;
            }
            .list-box {
                padding-bottom: 50px;
                ul {
                    padding-left: 60px;
                    li {
                        font-weight: normal;
                        font-size: $font-size-28;
                        color: #e20613;
                        line-height: 1.5;
                        padding-bottom: 5px;
                        text-transform: initial;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1550px) {
        .image-text {
            .section-list {
                .list-box {
                    ul {
                        li {
                            font-size: 1.2rem;
                        }
                    }
                }
                p {
                    font-size: 1.2rem;
                    text-align: center;
                }
            }
        }
        @media screen and (max-width: 1400px) {
            padding: 75px 5%;
            @media screen and (max-width: 1150px) {
                .image-text .text-box {
                    margin-top: 0;
                    margin-bottom: 35px;
                }
                @media screen and (max-width: 1024px) {
                    .image-text {
                        .section-list {
                            .list-box {
                                flex-direction: column;
                                .center {
                                    margin: -10px 0;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .image-text {
                            .section-list {
                                .list-box {
                                    ul {
                                        padding-left: 25px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#section-emplois {
    padding: 75px 8%;
    background: #dbdbdb;
    border-bottom: 7px solid #e20613;
    h4 {
        font-weight: 500;
        font-size: $font-size-28;
        color: #232323;
        padding-bottom: 35px;
    }
    .postes-emploi {
        padding-top: 60px;
        section.emplois-liste {
            .item-poste.emploi {
                width: 50%;
                padding-bottom: 75px;
                
                p {
                    font-size: $font-size-18;
                }
                a {
                    font-size: $font-size-18;
                    line-height: 1.6;
                    margin: 0;
                    color: #e20613;
                    transition: 0.3s;
                    &:hover {
                        color: #232323;
                        transition: 0.3s;
                    }
                }
                ul {
                    padding-left: 30px;
                    padding-top: 15px;
                    li {
                        font-weight: normal;
                        font-size: $font-size-18;
                        color: #000000;
                        line-height: 1.5;
                        padding-bottom: 5px;
                        text-transform: initial;
                    }
                }
                .btn {
                    width: 155px;
                    margin-top: 25px;
                }
                &:nth-child(odd) {
                    padding-right: 5%;
                }
            }
        }
    }
    @media screen and (max-width: 1400px) {
            padding: 75px 5%;
        @media screen and (max-width: 1024px){
            .postes-emploi {
                section.emplois-liste {
                    .item-poste.emploi {
                        width: 100%;
                        &:nth-child(odd) {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}

section#section-form {
    background: #000;
    padding: 75px 0;
    h4 {
        font-weight: 500;
        font-size: $font-size-26;
        color: $color-white;
        text-transform: initial;
        padding-bottom: 35px;
    }
    form .buttons {
        margin-left: 22.8%;
    }
    @media screen and (max-width: 1200px) {
        form div.label-wrapper.message {
            width: 285px;
        }
        form .buttons {
            margin-left: 27.8%;
        }
        @media screen and (max-width: $size-sm-max){
            .form-group.col-xs-12.col-sm-9.col-sm-push-1 {
                left: 2%;
                width: 90%;
            }
            form div.label-wrapper.message {
                width: 295px;
            }
            form label.filedoc {
                height: 35.13px;
            }
            @media screen and (max-width: $size-xs-max){
                .form-group.col-xs-12.col-sm-9.col-sm-push-1 {
                    left: 0;
                    width: 100%;
                    .flex-auto {
                        width: 100%;
                    }
                }
                .section-btn.col-xs-12.col-sm-9.col-sm-push-1.flex.justify-between {
                    flex-direction: column;
                    align-items: center;
                }
                form .buttons {
                    margin-left: 0;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    background: #000;
    padding: 75px 5%;
    .realisations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 85px;
        .bloc {
            h3 {
                font-weight: 500;
                font-size: $font-size-26;
                color: $color-white;
                padding-bottom: 10px;
                transition: 0.3s;
            }
            p {
                font-weight: normal;
                font-size: $font-size-18;
                color: $color-white;
                line-height: 1.3;
                transition: 0.3s;
            }
            a {
                font-weight: normal;
                font-size: $font-size-18;
                color: #e20613;
                line-height: 1.3;
                transition: 0.3s;
                &:hover {
                    color: #fff;
                    transition: 0.3s;
                }
            }
        }
        a.item {
            .image {
                position: relative;
                margin-bottom: 15px;
                .img-responsive {
                    width: 100%;
                }
                .img-plus {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    img {
                        transition: all .5s ease;
                        &:nth-child(1) {
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            position: absolute;
                            opacity: 0;
                            top: 0;
                            left: 0;
                        }
                    }
                }
                .overlay {
                    background-color: rgba(226, 6, 19, 0.6);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: all 0.3s ease-out;
                }
            }
            &:hover {
                .image {
                    .overlay {
                        opacity: 1;
                    }
                    .img-plus {
                        img {
                            transition: all .5s ease;
                            &:nth-child(1) {
                                opacity: 0;
                            }
                            &:nth-child(2) {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    #blocIntro_promotions {
        h3 {
            color: #232323;
            text-transform: initial;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 75px 5%;
    background: #000;
    .container {
        width: 1465px;
        .title {
            padding-bottom: 50px;
            p {
                color: $color-white;
            }
        }
        .section-info.flex {
            .left-side {
                width: 50%;
                margin-right: 50px;
            }
            .right-side {
                width: 50%;
            }
            h3 {
                font-weight: 500;
                font-size: $font-size-30;
                color: $color-white;
                text-transform: initial;
            }
        }
        .section-heures-form {
            padding-top: 50px;
            .left-side {
                width: 50%;
                margin-right: 50px;
                .info {
                    padding-top: 12px;
                }
            }
            .right-side {
                width: 50%;
            }
            .icon.form.mr3 {
                width: 50%;
            }
            h4 {
                font-weight: 500;
                font-size: $font-size-24;
                color: $color-white;
            }
        }
    }
    @media screen and (max-width: 1615px) {
        .container {
            width: 100%;
        }
        @media screen and (max-width: 1500px) {
            .container .section-heures-form .icon.form.mr3 {
                width: 60%;
            }
            @media screen and (max-width: 1400px) {
                .container .section-heures-form .icon.form.mr3 {
                    width: 75%;
                }
                form div.label-wrapper.bouton {
                    width: 235px;
                }
                @media screen and (max-width: $size-md-max) {
                    .container .section-heures-form .icon.form.mr3 {
                        width: 100%;
                    }
                    form div.label-wrapper.bouton {
                        width: 230px;
                    }
                    @media screen and (max-width: 1215px) {
                        .container {
                            .section-info.flex {
                                flex-direction: column;
                                .left-side {
                                    width: 100%;
                                    margin-bottom: 25px;
                                }
                                .right-side {
                                    width: 100%;
                                }
                            }
                            .section-heures-form {
                                flex-direction: column;
                                .left-side {
                                    width: 100%;
                                    margin-right: 0;
                                    margin-bottom: 25px;
                                }
                                .right-side {
                                    width: 100%;
                                }
                            }
                            .section-heures-form .icon.form.mr3 {
                                width: 25%;
                            }
                            .module-formulaire.contact {
                                margin-left: -110px;
                                .col-xs-12 {
                                    width: 92.5%;
                                }
                            }
                            form div.label-wrapper.bouton {
                                width: 275px;
                            }
                        }
                        @media screen and (max-width: $size-xs-max) {
                            .container {
                                padding: 0; 
                                .section-info.flex {
                                    h3 {
                                        font-size: 1.25rem;
                                    }
                                }
                                .module-formulaire.contact {
                                    margin-left: 0;
                                    .col-xs-12 {
                                        width: 100%;
                                        .flex-auto {
                                            width: 100%;
                                        }
                                    }
                                }
                                form div.label-wrapper.bouton {
                                    width: 0;
                                    margin-left: -15px;
                                }
                                .section-heures-form .icon.form.mr3 {
                                    display: none;
                                }
                            }
                            @media screen and (max-width: 360px) {
                                .container {
                                    .section-info.flex {
                                        h3 {
                                            font-size: 1.2rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    

}

@media screen and (max-width: $size-xs-max) {
   

}
